import React from 'react';
import './Header.scss';
import '../../resources/main.scss';
import { Link } from 'react-router-dom';
import { loginService } from '../../service/LoginService.jsx';
import { withRouter } from 'react-router';

class Header extends React.Component{
    constructor(props) {
      super(props);
    }

    logout = event => {
      event.preventDefault();

      loginService.logout()
        .then(response =>{
          loginService.removeToken();
          this.props.history.push('/login');
        })
        .catch(error => {
          console.log(error);
        });
    }

    render(){
        return(
            <div className='articles__header'>
                <Link to='/all-articles' className='standard__link'><h3 className='articles__header-title'>BLOGUL TĂU</h3></Link>   
                <div className='articles__header-actions'>
                    <Link to='/add-article' className='standard__link'>
                      <div className='articles__action-article-box articles__add-article-button'>
                          <h4 className='articles__add-article'>Adaugă un articol</h4>
                          <img className='articles__header-icon' src='/images/plus.svg' alt='plus'/>
                      </div>
                    </Link>
                    <Link to='/all-articles' className='standard__link'>
                          <h4 className='articles__menu-item'>Toate Articolele</h4>

                    </Link>
                        <h4 className='articles__menu-item' onClick={event => this.logout(event)}>Deconectează-te</h4>
  
                </div>
            </div>
        )
    }
}

export default withRouter(Header);

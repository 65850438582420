import React from 'react';
import './AllArticles.scss';
import '../../resources/main.scss';
import { Link } from 'react-router-dom';
import Header from '../../components/Header/Header.jsx';
import { articleService } from '../../service/ArticleService';

class AllArticles extends React.Component{
    constructor(props){
        super(props);

        this.state={
            articles: [],
            pagination: {}
        }
    }

    componentDidMount() {
      articleService.getArticles()
        .then(response => {
          let articles = this.state.articles;
          let pagination = { ...this.state.pagination };
          articles = response.data.articles;
          pagination = response.data.pagination;

          this.setState({ articles });
          this.setState({ pagination });
        })
        .catch(error => {
          console.log(error);
        })
    }
    render(){
        return(
            <div className='standard articles'>
                <Header/>
                <div className='articles__container'>
                    <h2 className='articles__title-page'>Toate Articolele</h2>
                    <div className='articles__wrapper'>
                        {this.state.articles && this.state.articles.map(article=>(
                            <div className='articles__card' key={article.id}>
                                <img src={article.main_image && article.main_image.url ? `${process.env.REACT_APP_API_URL}${article.main_image.url}`: '/images/generic_article.jpg'} className='articles__card-img' alt='article picture'/>
                                <div className='articles__card-info'>
                                    <h5 className='articles__date'>{article.date}</h5>
                                    <h4 className='articles__title'>{article.title}</h4>
                                    <p className='articles__preview'>{article.brief}</p>
                                    <Link to={`/add-article/${article.slug}`} className='articles__see-more'>Vezi mai mult</Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default AllArticles;

import axios from 'axios';
import { loginService } from './LoginService';

export const articleService = {
  saveArticle,
  getArticles,
  getArticleBySlug
}

function saveArticle(article) {
  let url = `${process.env.REACT_APP_API_URL}/api/articles${
    article.id ? `/${article.id}` : ''
  }`;

  const requestOptions = {
    method: article.id ? 'PUT' : 'POST',
    url,
    headers: {
      Accept: 'application/json'
    },
    data: createBodyRequest(article)
  };

  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios(requestOptions);
}

function getArticles(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/articles?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  const requestOptions = {
    headers: {
      Accept: 'application/json'
    }
  };

  if (loginService.loggedIn()) {
    requestOptions.headers['Authorization'] = loginService.getToken();
  }

  return axios.get(url, requestOptions);
}

function createBodyRequest(article) {
  let formData = new FormData();

  if (article.id) {
    formData.append('article[id]', article.id);
  }

  formData.append('article[title]', article.title);
  formData.append('article[slug]', article.slug);
  formData.append('article[description]', article.description);
  formData.append('article[meta_tags]', article.meta_tags);
  formData.append('article[meta_description]', article.meta_description);
  formData.append('article[main_image_alt]', article.main_image_alt);
  formData.append('article[article_posted]', article.articlePosted);

  article.articleSections.map(articleSection => {
    if (articleSection.id) {
      formData.append('article[article_sections_attributes][][id]', articleSection.id);
    }

    if (articleSection.article_id) {
      formData.append('article[article_sections_attributes][][article_id]', articleSection.article_id);
    }

    formData.append('article[article_sections_attributes][][tip]', articleSection.type);
    formData.append('article[article_sections_attributes][][component]', articleSection.component);
    formData.append('article[article_sections_attributes][][fe_component]', articleSection.feComponent);
    formData.append('article[article_sections_attributes][][value]', articleSection.value);
    formData.append('article[article_sections_attributes][][image_alt]', articleSection.image_alt);

    if (articleSection.type === 'image') {
      const image = articleSection.image;

      if (image) {
        if (image.url) {
          formData.append(`article[article_sections_attributes][][image]`, image.url);
        } else {
          formData.append(`article[article_sections_attributes][][image]`, image, image.name);
        }
      }

    }
  });

  article.references.map(reference => {
    formData.append('article[references_attributes][][title]', reference.title);
    formData.append('article[references_attributes][][url]', reference.url);
  });

  const main_image = article.main_image;

  if (main_image) {
    if (main_image.url) {
      formData.append(`article[main_image]`, main_image.url);
    } else {
      formData.append(`article[main_image]`, main_image, main_image.name);
    }
  }

  return formData;
}

function getArticleBySlug(slug) {
  const requestOptionsPost = {
      headers: {
        Accept: 'application/json'
      }
    };

    let url = `${process.env.REACT_APP_API_URL}/api/articles/get_by_slug/${slug}`;


   return axios.get(url, requestOptionsPost);
}

import React from 'react';
import './AddArticle.scss';
import AttachementField from '../../components/AttachementField/AttachementField';
import Header from '../../components/Header/Header.jsx';
import { articleService } from '../../service/ArticleService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';
import articleSectionTypes from '../../constants/ArticleSections';
import { Editor } from '@tinymce/tinymce-react';

class AddArticle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      article: {
        title: '',
        slug: '',
        main_image: '',
        main_image_alt: '',
        meta_tags: '',
        meta_description: '',
        description: '',
        articleSections: [],
        references: [],
        articlePosted: false
      },
      disabledDocument: false
    }

    this.notificationSystem = React.createRef();
  }

  componentDidMount() {
    if (this.props.match.params.slug) {
      articleService.getArticleBySlug(this.props.match.params.slug).then(response => {
        let article = { ...this.state.article };
        article = {
          ...response.data,
          articleSections: []
        };

        response.data.article_sections.map(article_section => {
          article.articleSections.push({
            ...article_section,
            type: article_section.tip,
            feComponent: article_section.fe_component,
          })
        });

        console.log(article);
        this.setState({ article });
      })
        .catch(err => console.log(err))
    }

  }

  onDropDocument = documents => {
    let article = { ...this.state.article };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      article.main_image = documents[0];

      self.setState({ article });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  onDropImageDocument = (documents, rejected, index) => {
    let article = { ...this.state.article };
    let reader = new FileReader();
    let self = this;

    reader.onload = function (upload) {
      let currentSection = article.articleSections[index];

      currentSection.image = documents[0];

      self.setState({ article });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let article = { ...this.state.article };
    article.main_image = null;
    article.main_image_filename = '';

    this.setState({ article });
    this.setState({ disabledDocument: false });
  };

  removeImageDocument = index => {
    let article = { ...this.state.article };
    let currentSection = article.articleSections[index];
    currentSection.image = null;
    currentSection.image_filename = '';

    this.setState({ article });
  };

  updateField = event => {
    const field = event.target.name;
    const article = { ...this.state.article };
    article[field] = event.target.value;
    this.setState({
      article
    });
  };

  updateEditorField = (value, field) => {
    const article = { ...this.state.article };
    article[field] = value;
    this.setState({
      article
    });
  }

  updateSectionField = (event, index) => {
    let article = this.state.article;

    let currentSection = article.articleSections[index];
    currentSection[event.target.name] = event.target.value;

    this.setState({ article })
  }

  updateEditorSectionField = (value, index) => {
    let article = this.state.article;

    let currentSection = article.articleSections[index];
    currentSection.value = value;

    this.setState({ article });
  }

  addSection = type => {
    const section = Object.assign({}, articleSectionTypes.find(articleSection => articleSection.type === type));
    let article = this.state.article;

    article.articleSections.push(section);
    this.setState({ article });
  }

  removeSection = index => {
    let article = this.state.article;
    article.articleSections.splice(index, 1);
    this.setState({ article });
  }

  addReference = () => {
    let article = this.state.article;

    article.references.push({
      title: '',
      url: ''
    });

    this.setState({ article });

    console.log(article);
  }

  removeReference = index => {
    let article = this.state.article;
    article.references.splice(index, 1);
    this.setState({ article });
  }

  updateReferenceField = (event, index) => {
    let article = this.state.article;

    let currentSection = article.references[index];
    currentSection[event.target.name] = event.target.value;

    console.log(currentSection)

    this.setState({ article });
  }

  saveArticle = event => {
    event.preventDefault();
    const notification = this.notificationSystem.current;

    articleService.saveArticle(this.state.article)
      .then(response => {
        notification.addNotification({
          message: `Articolul a fost ${this.state.article.id ? 'editat' : 'adaugat'} cu success.`,
          level: 'success',
          position: 'tc'
        });
        this.props.history.push('/all-articles');
      })
      .catch(error => {
        notification.addNotification({
          message: 'A aparut o problema la adaugarea articolului.',
          level: 'error',
          position: 'tc'
        });
      })
  }
  handleCheckboxChange = event => {
    const field = event.target.name;
    const article = { ...this.state.article };
    article[field] = event.target.checked;
    this.setState({ article });
  }
  render() {
    return (
      <div className='standard add'>
        <Header />
        <div className='add__container'>
          <div className='add__left-section'>

            <h2 className='add__actions'>ACȚIUNI</h2>
            <div className="add__fixed">
              <button className='add__add-button' onClick={() => this.addSection('subTitle')}>
                <h5 className='add__indication' >ADAUGĂ SUBTITLU</h5>
              </button>
              <button className='add__add-button' onClick={() => this.addSection('paragraf')}>
                <h5 className='add__indication'>ADAUGĂ PARAGRAF</h5>
              </button>
              <button className='add__add-button' onClick={() => this.addSection('image')}>
                <h5 className='add__indication'>ADAUGĂ IMAGINE</h5>
              </button>
              <button className='add__add-button' onClick={() => this.addSection('videoUrl')}>
                <h5 className='add__indication'>ADAUGĂ URL VIDEO</h5>
              </button>
            </div>

          </div>
          <div className='add__right-section'>
            <h3 className='add__title'>{this.state.article.id? "Editează articolul":"Adaugă un articol"}</h3>
            <form className='add__form' onSubmit={this.saveArticle}>
              <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} titlul:</h4>
              <input
                className='add__input'
                type="text"
                name="title"
                onChange={this.updateField}
                value={this.state.article.title}
                placeholder="Title" />

              <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} slug:</h4>
              <input
                className='add__input'
                type="text"
                name="slug"
                onChange={this.updateField}
                value={this.state.article.slug}
                placeholder="Slug" />
              <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} Meta descriere:</h4>
              <input
                className='add__input'
                type="text"
                name="meta_description"
                onChange={this.updateField}
                value={this.state.article.meta_description}
                placeholder="Meta Description" />

              <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} taguri (separate prin virgula):</h4>
              <h5 className='add__label'>Tagurile sunt folosite la cautarile articolelor si vor fi adaugate ca si meta tags pentru Google SEO.</h5>
              <input
                className='add__input'
                type="text"
                name="meta_tags"
                onChange={this.updateField}
                value={this.state.article.meta_tags}
                placeholder="Meta Tags" />

              <div>
                <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} imagine principala articol:</h4>
                <AttachementField
                  onDrop={this.onDropDocument}
                  removeDocument={this.removeDocument}
                  disabledDocuments={this.state.disabledDocument}
                  document={this.state.article.main_image}
                  documentFilename={
                    this.state.article.main_image_filename
                  }
                  type="image/*"
                />
                <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} alt imagine principală:</h4>
                <input
                  className='add__input'
                  type="text"
                  name="main_image_alt"
                  onChange={this.updateField}
                  value={this.state.article.main_image_alt}
                  placeholder="Main Image Alt" />
                <h4 className='add__label'>{this.state.article.id? "Editează":"Adaugă"} descriere/brief</h4>
                <div className='add__input-box'>
                  <Editor
                    apiKey="ls15a02h3hmn3erw7e3mar50pv1w85ivm85bho7dv6oah4zn"
                    id="descriptionEditor"
                    value={this.state.article.description}
                    onEditorChange={value => this.updateEditorField(value, 'description')}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar: 'bold italic | link | removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                    }}
                  />
                </div>
                {this.state.article.articleSections.map((articleSection, index) => (
                  <div key={index} className='add__input-box'>
                    <h4 className='add__label'>{articleSection.label}</h4>
                    <div className='add__input-wrapper'>
                      {articleSection.component === 'input' && (
                        <input
                          className='add__input'
                          type="text"
                          name="value"
                          onChange={event => this.updateSectionField(event, index)}
                          value={articleSection.value}
                        />
                      )}
                      {articleSection.component === 'editor' && (
                        <Editor
                          apiKey="ls15a02h3hmn3erw7e3mar50pv1w85ivm85bho7dv6oah4zn"
                          id={`section-editor${index}`}
                          value={articleSection.value}
                          onEditorChange={event => this.updateEditorSectionField(event, index)}
                          init={{
                            height: 300,
                            width: '90%',
                            menubar: false,
                            plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar: 'bold italic | link | removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                          }}
                        />
                      )}
                      {articleSection.component === 'dropzone' && (
                        <div className="add__dropzone-area">
                          <AttachementField
                            onDrop={this.onDropImageDocument}
                            removeDocument={this.removeImageDocument}
                            disabledDocuments={this.state.disabledImageDocument}
                            currentArticleSectionIndex={index}
                            document={articleSection.image}
                            documentFilename={
                              articleSection.image_filename
                            }
                            type="image/*"
                          />
                          <h4 className='add__label'>Image alt:</h4>
                          <input
                            className='add__input'
                            type="text"
                            name="image_alt"
                            onChange={event => this.updateSectionField(event, index)}
                            value={articleSection.image_alt}
                            placeholder="Image Alt" />
                        </div>
                      )}
                      <img src='/images/close-icon-black.png' className='add__remove-input' alt='remove' onClick={() => this.removeSection(index)} />
                    </div>
                  </div>
                ))}
              </div>
              <div className="add__active-article">
                <h4 className='add__label'>Postează articol:</h4>
                <label class="switch">
                  <input type="checkbox"
                    name="articlePosted"
                    onChange={this.handleCheckboxChange}
                    required
                    value={this.state.article.articlePosted} />
                  <span class="slider round"></span>
                </label>
              </div>


              <div className="add__references">
                <div className="add__references-wrapper">
                  <h4 className='add__label'>Adaugă referinte</h4>
                  <button type="button" className="add__button" onClick={this.addReference}><h5 className="add__button-writing">Referinta noua</h5></button>
                </div>
                {this.state.article.references.map((reference, index) => (
                  <div key={index} className="add__reference">
                    <div className="add__reference--input">
                      <img src='/images/close-icon-black.png' className='add__remove-input' alt='remove' onClick={() => this.removeReference(index)} />
                      <h4 className='add__label'>Titlu Referinta:</h4>
                      <input
                        className='add__input'
                        type="text"
                        name="title"
                        onChange={event => this.updateReferenceField(event, index)}
                        value={reference.title}
                        placeholder="Title" />
                    </div>
                    <div className="add__reference--input">
                      <h4 className='add__label'>Url Referinta:</h4>
                      <input
                        className='add__input'
                        type="text"
                        name="url"
                        onChange={event => this.updateReferenceField(event, index)}
                        value={reference.url}
                        placeholder="Url" />
                    </div>
                  </div>
                ))}

              </div>

              <button
                type="submit"
                className="add__button"
              >
                <h5 className="add__button-writing">{this.state.article.id ? 'Editează' : 'Adaugă'} articol</h5>
              </button>
            </form>
          </div>
        </div>
        <NotificationSystem
          ref={this.notificationSystem}
          style={NotificationSystemStyle}
        />
      </div>
    )
  }
}

export default AddArticle;
